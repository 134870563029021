import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }

type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

type StaticPostNode = {
  title: string
  link: string
  description?: string
  featuredImage?: any
}

type ContentfulPostNode = {
  title: string
  subtitle?: string
  publishDate: string
  childContentfulBlogPostPostDescriptionTextNode: any
  featuredImage?: any
}

type MarkdownRemarkPostNode = {
  frontmatter: {
    title: string
    subtitle?: string
    date: string
    description?: string
    featuredImage?: any
  }
}

type PostNode = XOR<
  XOR<ContentfulPostNode, MarkdownRemarkPostNode>,
  StaticPostNode
>

type Props = {
  type: "contentful" | "markdownRemark" | "static"
  path: string
  node: PostNode
}

const isNodeOfType = <T extends PostNode>(type: string) => (
  checkType: string,
  node: PostNode
): node is T => checkType === type

const isContentfulNode = isNodeOfType<ContentfulPostNode>("contentful")

const isMarkdownNode = isNodeOfType<MarkdownRemarkPostNode>("markdownRemark")

const isStaticNode = isNodeOfType<StaticPostNode>("static")

const Post: React.ComponentType<Props> = ({ type, path, node }) => {
  if (isContentfulNode(type, node)) {
    return <ContentfulPost key={`${path}-post`} path={path} {...node} />
  }

  if (isMarkdownNode(type, node)) {
    return <MarkdownPost key={`${path}-post`} path={path} {...node} />
  }

  if (isStaticNode(type, node)) {
    return <StaticPost key={`${path}-post`} {...node} />
  }
}

const ContentfulPost: React.ComponentType<{
  path: string
} & ContentfulPostNode> = ({
  path,
  title,
  subtitle,
  childContentfulBlogPostPostDescriptionTextNode,
  featuredImage,
  publishDate: date,
}) => {
  const hasFeaturedImage = Boolean(featuredImage)
  const { postDescription } =
    childContentfulBlogPostPostDescriptionTextNode || {}
  const parsedDescription =
    postDescription && postDescription.replace(/\n/g, "<br>")

  return (
    <PostContent
      slug={path}
      title={title}
      subtitle={subtitle}
      hasFeaturedImage={hasFeaturedImage}
      featuredImage={
        hasFeaturedImage ? (
          <Img key={featuredImage?.fluid?.src} fluid={featuredImage.fluid} />
        ) : null
      }
      parsedDescription={parsedDescription}
      date={date}
    />
  )
}

const MarkdownPost: React.ComponentType<{
  path: string
} & MarkdownRemarkPostNode> = ({
  path,
  frontmatter: { title, subtitle, date, description, featuredImage },
}) => {
  const hasFeaturedImage = Boolean(featuredImage)
  const parsedDescription = description && description.replace(/\n/g, "<br>")

  return (
    <PostContent
      slug={path}
      title={title}
      subtitle={subtitle}
      hasFeaturedImage={hasFeaturedImage}
      featuredImage={
        hasFeaturedImage ? (
          <Img
            key={featuredImage?.childImageSharp?.fluid?.src}
            fluid={featuredImage.childImageSharp.fluid}
          />
        ) : null
      }
      parsedDescription={parsedDescription}
      date={date}
    />
  )
}

const StaticPost: React.ComponentType<StaticPostNode> = ({
  link,
  title,
  featuredImage,
  description,
}) => {
  const hasFeaturedImage = Boolean(featuredImage)
  const parsedDescription = description && description.replace(/\n/g, "<br>")

  return (
    <PostContent
      link={link}
      title={title}
      hasFeaturedImage={hasFeaturedImage}
      featuredImage={
        hasFeaturedImage ? (
          <Img
            key={featuredImage?.childImageSharp?.fluid?.src}
            fluid={featuredImage.childImageSharp.fluid}
          />
        ) : null
      }
      parsedDescription={parsedDescription}
      date={null}
    />
  )
}

type Content = XOR<{ link: string }, { slug: string }> & {
  title: string
  subtitle?: string
  hasFeaturedImage: boolean
  featuredImage?: any
  parsedDescription?: string
  date?: string
}

const LinkWrapper = ({ link, slug, children, ...props }) => {
  const relativeLink = slug || (link?.charAt(0) === "/" ? link : null)

  if (relativeLink) {
    return (
      <Link to={relativeLink} {...props}>
        {children}
      </Link>
    )
  }

  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    )
  }

  return null
}

const PostContent: React.ComponentType<Content> = ({
  slug,
  title,
  subtitle,
  hasFeaturedImage,
  featuredImage,
  parsedDescription,
  date,
  link,
}) => {
  return (
    <div key={slug || title} className="grid__post">
      <LinkWrapper link={link} slug={slug}>
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}
      </LinkWrapper>
      {hasFeaturedImage ? (
        <LinkWrapper link={link} slug={slug} className="grid__post__image">
          {featuredImage}
        </LinkWrapper>
      ) : null}
      {parsedDescription && (
        <p dangerouslySetInnerHTML={{ __html: parsedDescription }}></p>
      )}
    </div>
  )
}

export default Post
