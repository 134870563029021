const moment = require("moment")
const { get } = require("lodash")

const getContentfulPath = edge => {
  return `/${moment(edge.node.publishDate).format("YYYY-MM-DD")}-${
    edge.node.slug
  }`
}

// https://github.com/contentful/rich-text/issues/70#issuecomment-585367684
const getImagesFromRichText = edge => {
  return get(edge, "node.content.json.content", []).reduce((acc, c) => {
    const url = get(c, "data.target.fields.file.en-GB.url")
    if (c.nodeType == "embedded-asset-block" && url) {
      return [...acc, url]
    }
    return acc
  }, [])
}

const joinedPostList = contentfulEdges => {
  const postPages = []

  contentfulEdges.forEach(edge => {
    const images = getImagesFromRichText(edge)
    const path = getContentfulPath(edge)

    postPages.push({
      type: "contentful",
      node: edge.node,
      path: path,
      context: {
        postPath: path,
        slug: edge.node.slug,
        featuredImageUrl: edge.node.featuredImage,
        date: edge.node.publishDate,
        rawDate: edge.node.rawDate,
        images,
      },
    })
  })

  return postPages.sort(
    (a, b) =>
      new Date(b.context.rawDate).getTime() -
      new Date(a.context.rawDate).getTime()
  )
}

module.exports = {
  joinedPostList,
}
