import React, { useMemo } from "react"
import Grid from "../grid/grid"
import Post from "./post"

const PostListContent = ({ posts }) => {
  const items = useMemo(
    () => posts.map(post => ({ ...post, id: post.path || post.id })),
    [posts]
  )

  return <Grid items={items}>{post => <Post key={post.path} {...post} />}</Grid>
}

export default PostListContent
